<template>
    <v-container fluid class="fill-height align-start pa-0">
        <v-row class="fill-height" no-gutters justify="center">
            <v-col cols="12" md="11" lg="11">
                <v-sheet tile class="fill-height px-5 py-3">
                    <slot/>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "OrylContainer"
    }
</script>

<style scoped>

</style>
